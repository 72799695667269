<template>
  <div class='commonScroll_container'  ref='commonScrollList'>
    <div class="commonScroll_content">
        <div  v-for="(citems,cindex) in dataList" :key="cindex">
            <slot :citems="citems" :cindex="cindex"></slot>
        </div>
    </div>
  </div>
</template>
<script>
import BScroll  from 'better-scroll'
export default {
  name:'commonScroll',
  props:{
      dataList:{
          type:Object,
          default:()=>{
              return []
          }
      }
  },
  data(){
      return {
          data: []
      }
  },
  created(){
    this.initScroll()
    this.data = [...this.dataList];
  },
  methods:{
    updateList(dataList,isDone){
        this.data = [...dataList]
        this.initScroll(isDone)
    },
    initScroll(isDone,isTop){
      this.$nextTick(()=>{
        if(!this.scroll){
          this.scroll = new BScroll(this.$refs.commonScrollList,{
            click:true,
            mouseWheel:true,
            useTransition:false
          })
        }else{
          this.scroll.refresh();
          if(isDone){
              setTimeout(()=>{
                  this.scroll.scrollTo(0,this.scroll.maxScrollY)
              })
          }
          if(isTop){
              setTimeout(()=>{
                  this.scroll.scrollTo(0,0)
              })
          }
        }
      })
    },
  }
}
</script>
<style>
.commonScroll_container{
  height:100%;
  width:100%;
  position: relative;
  overflow: hidden;
}
</style>