<template>
  <div class="meeting_info_container global_full_screen global_meeting_padding">
    <!-- <div class="meeting_info_screen_block" v-if="!meetingData.meet_info.is_test">
      <div class="meeting_info_meeting_text">
        <span>会议编号：</span><span>{{meetingData.meet_info?.meeting_number || "202002122212"}}</span>
      </div>
      <div class="meeting_info_meeting_text">
        <span>在线人数：</span><span>{{meetingData.online_count || 1}}</span>
      </div>
      <div class="meeting_info_meeting_text">
        <span>主持专家：</span><span>{{meetingData.meet_info?.is_online ? "在线" : "离线"}}</span>
      </div>
    </div> -->
    <div class="meeting_info_screen_block">
      <p class="meeting_info_screen_tips">{{getTipContent()}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name:"meetingInfo",
  props:{
    meetingData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data(){
    return {}
  },
  methods:{
    getTipContent(){
      let content = this.meetingData.is_test ? "建议每页讲课为60秒内，超过60秒系统将不能继续录制" : "建议每页讲课为60秒内，超过60秒系统将不能继续录制"
      // let content = this.meetingData.is_test ? "每页讲课时间为1分钟内，解说词不超过220字" : "建议讲课时间为1分钟内，如超过1分钟系统将不能继续录制"
      return content
    },
  }
}
</script>
<style>
.meeting_info_container{
  display: flex;
  align-items: center;
  background:#0100ca;
  color:#fff;
  font-weight: 400;
  font-size:3.2vw;
}
.meeting_info_screen_block{
  height:3.2vw;
  width:100%;
  display: flex;
  justify-content: space-between;
}
.meeting_info_screen_tips{
  width: 100%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-size:3.3vw;
}
</style>