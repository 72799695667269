import tools from '@/common/tool'

const randomId = () => {
  return String(Math.floor(Math.random() * 1e10))
}
const recorder = {
  stream:null,
  chunks:[],
  wx:null,
  recorder:null,
  callback:null,
  voiceLength:0,
  isAutoStart:false,
  init: async (wx,callback) => {
    recorder.recorder = wx.wx
    callback()
  },
  startRecord: async () => {
    return new Promise((resolve,reject)=>{
      if(tools.isLocal()){
        resolve({status:200,data:{localId:randomId()}})
        return;
      }
      recorder.recorder.startRecord({
        success: (res) => {
          resolve({status:200,data:res})
        },
        cancel: () => {
          console.log("取消录音",err)
          reject({status:400,data:"拒绝了录音权限"})
        },
        fail: (err) => {
          console.log("开始录音报错",err)
          reject({status:500,data:JSON.stringify(err)})    
        }

    })
  });
  },
  stopRecord: async () => {
    return new Promise((resolve,reject)=>{
      if(tools.isLocal()){
        resolve({status:200,data:{localId:randomId()}})
        return;
      }
      recorder.recorder.stopRecord({
        success: (res) => { 
          console.log("停止录制",res)
          resolve({status:200,data:res})
        },
        fail: (err) => { 
          console.log("录音失败",err)
          reject({status:500,data:JSON.stringify(err)}) 
        },
      });
    })
  },
  upload: async (localId) => {
    return new Promise((resolve,reject)=>{
      if(tools.isLocal()){
        resolve({status:200,data:{serverId:randomId()}})
        return;
      }
      recorder.recorder.uploadVoice({
        localId: localId,
        isShowProgressTips: 0,
        success: (res) => { 
          console.log("上传成功n",res)
          resolve({status:200,data:res})
        },
        fail: (err) => { 
          console.log("上传录音",err)
          reject({status:500,data:JSON.stringify(err)}) 
        },
      });
    })
  },
  playVoice(localId){
    if(tools.isLocal()) return;
    console.log("播放语音",localId)
    recorder.recorder.playVoice({
      localId
    });
  },
  stopVoice(localId){
    if(tools.isLocal()) return;
    console.log("停止播放语音",localId)
    recorder.recorder.stopVoice({
      localId
    });
  },
  
  download: async (item) => {
    return new Promise((resolve,reject)=>{
      if(tools.isLocal()){
        resolve({status:200,data:{localId:randomId()}})
        return;
      }
      recorder.recorder.downloadVoice({
        serverId: item.voice_id,
        isShowProgressTips: 0,
        success: (res)=>{
          console.log("下载成功",res)
          resolve({status:200,data:res})
        },
        fail: (err)=>{
          console.log("下载失败",err,item)
          reject({status:500,data:JSON.stringify(err)}) 
        },
      });
    })
  }
}

export default recorder
