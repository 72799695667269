<template>
  <div class="voiceItem_container">
      <img :src="config.list[voiceTime.pageIndex]" alt="">
  </div>
</template>
<script>
export default {
  name:"voiceItem",
  props:{
    play:{
      type:Boolean,
      default:false
    }
  },
  data(){
    return {
      config:{
        list:[
          require('@/assets/images/voice/1.png'),
          require('@/assets/images/voice/2.png'),
          require('@/assets/images/voice/3.png'),
        ]
      },
      voiceTime:{
        startTime:0,
        pageIndex:0,
        time:null,
        speed:300
      }
    }
  },
  created(){
    console.log(this.play)
    if(this.play)   this.voiceTime.startTime = this.$tools.getTime();
    this.initVoiceTime()
  },
  methods:{
    initVoiceTime(){
      if(this.voiceTime.time){
        clearTimeout(this.voiceTime.time)
        this.voiceTime.time = null
      }
      if(this.voiceTime.startTime == 0) return;
      this.voiceTime.pageIndex += 1;
      this.voiceTime.pageIndex = this.config.list[this.voiceTime.pageIndex] ? this.voiceTime.pageIndex : 0;
      this.voiceTime.time = setTimeout(()=>{ this.initVoiceTime() },this.voiceTime.speed)
    }
  }
}
</script>
<style>
.voiceItem_container{
  width:8vw;
  height:8vw;
}
.voiceItem_container img{
  width:100%;
  height:100%;
}
</style>